import React, { useState, useContext } from 'react';
import Layout from "../components/layout"
import LinkFade from '../components/LinkFade';
import { useIdleTimer } from 'react-idle-timer'
import { graphql } from "gatsby"

const Touchscreen = ({ location, data, pageContext, entry }) => {

  const [slideInMenuItems, setSlideInMenuItems] = useState(data.strapiTouchscreens && data.strapiTouchscreens.mainmenupage && data.strapiTouchscreens.mainmenupage.Icons && data.strapiTouchscreens.mainmenupage.Icons.length > 1 && entry.state.opts === "back");

  const handleOnIdle = event => {
    menuClick(false);
  }

  const handleOnActive = event => {
    console.log('TS user is active', event)
    console.log('TS time remaining', getRemainingTime())
  }

  const handleOnAction = (e) => {
    console.log('TS user did something', e)
  }

  const { getRemainingTime, getLastActiveTime } = useIdleTimer({
    timeout: 180000, // 3 min
    onIdle: handleOnIdle,
    onActive: handleOnActive,
    onAction: handleOnAction,
    debounce: 500
  })

  const menuClick = (slideIn) => {
    if (data.strapiTouchscreens.mainmenupage.Icons.length > 1) {
      setSlideInMenuItems(slideIn);
    }
  }

  const getTitle = (item) => {
    var title = "";
    if (item && item.Title) {
      // if (language == "maori" && item.TitleMaori) {
      //   title = item.TitleMaori;
      // } else {
        title = item.Title;
      // }
    }
    return title.replace(/DL[0-9]{1,2}[- ]?/, "");
  }

  function menuType(menuData, path) {
    // return <pre>{JSON.stringify(menuData, null, 4)}</pre>
    var linkType, targetId;
    if (menuData.slideshow !== null) {
      linkType = "slideshow";
      targetId = menuData.slideshow.id;
    } else {
      linkType = "menu";
      targetId = menuData.menu_page.id;
    }
    return <LinkFade key={Math.random()} url={`${path}/${linkType}/${targetId}`}>
      <img loading="eager" alt="" src={menuData.Image.localFile.publicURL} />
      <p>{ getTitle(menuData) }</p>
    </LinkFade>
  }

  var linkURL, linkTypex, targetIdx;
  if (data.strapiTouchscreens && data.strapiTouchscreens.mainmenupage && data.strapiTouchscreens.mainmenupage.Icons && data.strapiTouchscreens.mainmenupage.Icons.length == 1) {
    if (data.strapiTouchscreens.mainmenupage.Icons[0].slideshow !== null) {
      linkTypex = "slideshow";
      targetIdx = data.strapiTouchscreens.mainmenupage.Icons[0].slideshow.id;
    } else {
      linkTypex = "menu";
      targetIdx = data.strapiTouchscreens.mainmenupage.Icons[0].menu_page.id;
    }
    linkURL = `/touchscreens/${data.strapiTouchscreens.TouchscreenID}/${linkTypex}/${targetIdx}`;
  }

  if (data.strapiTouchscreens === null)
    return <></>

  var content = <div className="page-container">
    <nav className={"nav " + (slideInMenuItems ? "fadeIn" : "fadedOut")}>
      <a className="back" role="button" onClick={() => {menuClick(false)}}></a>
    </nav>

    <video autoPlay loop muted className={"bg-video " + (slideInMenuItems ? "halfFade" : "")} role="button" onClick={() => {menuClick(true)}}>
      <source src={data.strapiTouchscreens.AttractorVideo.localFile.publicURL} type="video/mp4" />
    </video>

    <div className={"touchscreen-text " + (slideInMenuItems ? "touchscreen-text-slide-out" : "")} role="button" onClick={() => {menuClick(true)}}>
      <h1>{ getTitle(data.strapiTouchscreens) }</h1>
      {/* <p>{data.strapiTouchscreens.Body}</p> */}
      <h3>Discover more ></h3>
    </div>
    <div className={"page-container touchscreen-menu-items-container " + (slideInMenuItems ? "touchscreen-menu-items-container-slide-in" : "")}>
      {/* <h3 className="subpage-touchscreen-title">{ data.strapiTouchscreens.Title.replace(/DL[0-9]{1,2}[- ]?/, "") }</h3> */}
      <h1>{ getTitle(data.strapiTouchscreens) }</h1>
      <div className="touchscreen-menu-items">
        { data.strapiTouchscreens.mainmenupage.Icons.map((menu, i) => {
          return menuType(menu, `/touchscreens/${data.strapiTouchscreens.TouchscreenID}`);
        })}
      </div>
    </div>
  </div>

  // return <pre>{JSON.stringify(language, null, 4)}</pre>

  return <Layout className={"touchscreen-page touchscreen-page" + pageContext.id} homePath={pageContext.homePath} timeoutOverride={3600} styles={pageContext.styles}>
    { linkURL === null ? content : <LinkFade url={linkURL}>{content}</LinkFade>}
  </Layout>
}

export const pageQuery = graphql`
  query TouchScreenPageQuery($id: String = "") {
    strapiTouchscreens(TouchscreenID: {eq: $id}) {
      id
      Body
      TouchscreenID
      Title
      AttractorVideo {
        localFile {
          publicURL
        }
      }
      mainmenupage {
        id
        Title
        Icons {
          Image {
            localFile {
              publicURL
            }
          }
          Title
          menu_page {
            Title
            id
          }
          slideshow {
            Title
            id
          }
        }
      }
    }
  }
`
export default Touchscreen
